"use client";

import { useEsiVars } from "@yahoo-creators/edge";
import { Footer } from "@yahoo-creators/footer";
import { type FC } from "react";
import { type EdgeSideIncludes } from "@/configs/esi";
import { useConfigContext } from "@/contexts/ConfigContext";
import { useIsModal } from "@/contexts/PathProvider";
import { useRequestContext } from "@/lib/request/client";

export const SiteAltFooter: FC = () => {
  const { lang, partner, site } = useRequestContext();
  const { footer: footerConfig } = useConfigContext();
  const isModal = useIsModal();

  const { acookieFields } = useEsiVars<Pick<EdgeSideIncludes, "acookieFields">>(
    {
      acookieFields: "",
    },
  );

  if (isModal) {
    return null;
  }

  const links = footerConfig.links;
  if (!links) {
    return null;
  }
  return (
    <Footer
      acookieFields={acookieFields}
      context={{ lang, partner, site }}
      links={links}
    />
  );
};
